@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

body {
  font-family: 'Inconsolata', monospace;
  height: 100%;
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
}

.app-display {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #97feed;
}

.logo {
  width: 240px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
}

.predefined-times {
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 99;
}

.timer-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.timer-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: lightgray;
  position: relative;
}

.timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Styling for the text inside the timer */
  font-size: 80px;
  color: white;
}

.timer-circle {
  /* Existing styles */
  position: relative;
  background-image: url('./digital-timer-1.png');
  background-size: 320px 320px;
  background-position: -10px -10px;
  background-repeat: no-repeat;
  z-index: 99;
}

.timer-divider {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100vh;
  background-color: black;
  z-index: 9;
  opacity: 0;
}

.interactive-area {
  position: relative;
  right: -50%;
  top: -50%;
  bottom: 0;
  width: 100%;
  height: 200%;
  border: 1px dotted black;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  background-color: rgba(255, 255, 255, 0.1);
}
.interactive-area:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.instruction-text {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  text-align: right;
  font-size: 18px;
  width: 100%;
}

.scroll-up {
  height: 80px;
  position: absolute;
  top: 5px;
  right: -30px;
  transform: rotate(180deg);
}

.scroll-down {
  height: 80px;
  position: absolute;
  bottom: 5px;
  right: -30px;
}

button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 5px 10px;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(var(--color-button-text));
  /* transition: box-shadow var(--duration-short) ease; */
  appearance: none;
  /* background-color: rgba(var(--color-button),var(--alpha-button-background)); */
  font-size: 22px;
  letter-spacing: 0px;
  background: transparent;
  width: auto;
  cursor: pointer;
  transition: all 0.3s ease;
}
button:hover {
  opacity: 0.5;
}

.start-button {
  position: absolute;
  /* top: 0; */
  /* bottom: 0px; */
  margin: 0 auto;
  z-index: 99;
  background: white;
  height: 35px;
  color: black;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -50%;
  right: -15px;
  width: 100px;
  margin: 110% auto;
}

.reset-button {
  position: absolute;
  /* top: 0; */
  /* bottom: 0px; */
  margin: 0 auto;
  z-index: 99;
  background: white;
  height: 35px;
  color: black;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  right: 15px;
  width: 100px;
  margin: 110% auto;
}

.product-links {
  text-align: center;
}

.product-link {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  text-align: center;
  font-size: 18px;
}

.product-link {
  width: 200px;
  display: inline-block;
  color: #071952;
  text-decoration: none;
  /* margin: 0 -0.4em; */
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background-image: linear-gradient(to right,rgba(255,225,0,.7) 50%,rgba(255,225,0,.3));
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: color var(--duration-short) ease;
}

@media (max-width: 768px) {
  .timer-container {
    top: 200px;
    bottom: initial;
  }
  .scroll-down, .scroll-up {
    position: absolute;
    height: 60px !important;
    right: 5px !important;
  }
  .interactive-area {
    right: 30px;
    width: calc(100% + 60px);
    height: 460px;
    top: -15px;
  }
  .instruction-text {
    position: absolute;
    bottom: 0;
    right: 20px;
    padding: 10px;
    text-align: right;
    font-size: 18px;
    width: 100%;
  }
  .product-link {
    bottom: 20px;
  }
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.flash-animation {
  animation: flash 0.5s 6;
}